<script>
import TemplateHeaderTable from '@/components/TemplateHeaderTable'
import { getParams } from '../../command/netTool'
import TableButton from '@/components/TableButton'
import Table from './table.vue'

export default {
  name: 'exchange',
  data() {
    return {
      visible: false,
      dataList: [
        {
          serial: '1',
          mainName: '农村集体“三资”监管系统',
          keys: 'SJDG809SSHDNSCJCJHA',
          opener: 'HSKSWNDSDDOSDKA1',
          ips: '152.188.1.114',
          time: '2021-01-11 08:12:00',
          frequency: '1',
          Way: '1',
          status: '0',
          log: '日志',
          a1: '详情',
        },
        {
          serial: '2',
          mainName: '浙江省海洋渔业执法总队',
          keys: 'DSADSFJSSHNDSSCJCJHA',
          opener: 'HFHFKSIW89DK889WD',
          ips: '112.138.1.114',
          time: '2021-02-01 09:07:00',
          frequency: '0',
          Way: '0',
          status: '1',
          log: '日志',
          a1: '详情',
        },
        {
          serial: '3',
          mainName: '慈溪市气象局气象监测站',
          keys: 'SJDGHSJ088SA80FISS9A90D',
          opener: 'HSKSWNDSDOSDA90D',
          ips: '152.123.1.13',
          time: '2021-02-11 14:22:07',
          frequency: '0',
          Way: '1',
          status: '1',
          log: '日志',
          a1: '详情',
        },
      ],
    }
  },
  computed: {},
  mounted() {},
  methods: {
    getHeader() {
      return [
        {
          name: '系统名称',
          type: 'input',
          key: '',
          labelCol: { span: 8 },
          wrapperCol: { span: 16 },
        },
        {
          name: '姓名',
          type: 'input',
          key: '',
        },
      ]
    },
    getButton() {
      return [
        {
          name: '添加',
          type: 'primary',
          onClick: () => {
            this.$router.push('/exchange/detail')
          },
        },
        {
          name: '手动采集 (导出excel)',
          onClick: (data) => {
            let dataT = getParams(data)
            // downloadAction(`/api/dataoperation/collectionMainBody/mainBodyExport${dataT}`, '主体列表文件.xlsx')
          },
        },
      ]
    },
    getColumns() {
      return [
        {
          title: '序号',
          dataIndex: 'serial',
          align: 'left',
        },
        {
          title: '系统名称',
          dataIndex: 'mainName',
          align: 'left',
        },
        {
          title: 'KEY',
          dataIndex: 'keys',
          align: 'left',
        },
        {
          title: '密钥',
          dataIndex: 'opener',
          align: 'left',
        },
        {
          title: '白名单IP',
          dataIndex: 'ips',
          align: 'left',
        },
        {
          title: '交换时间',
          dataIndex: 'time',
          align: 'left',
        },
        {
          title: '交换频率',
          dataIndex: 'frequency',
          align: 'left',
          filters: [
            {
              text: '实时',
              value: '0',
            },
            {
              text: '每月',
              value: '1',
            },
          ],
          render: ({ text }) => ['实时', '每月'][text],
        },
        {
          title: '交换方式',
          dataIndex: 'Way',
          align: 'left',
          filters: [
            {
              text: '手动',
              value: '0',
            },
            {
              text: '自动',
              value: '1',
            },
          ],
          render: ({ text }) => ['手动', '自动'][text],
          // render: ({ text }) => {
          //   return text == '0' ? (
          //     <span style={{ color: '#5fce60' }}>手动</span>
          //   ) : (
          //     <span style={{ color: '#de350c' }}>自动</span>
          //   )
          // },
        },
        {
          title: '交换状态',
          dataIndex: 'status',
          align: 'left',
          filters: [
            {
              text: '成功',
              value: '0',
            },
            {
              text: '失败',
              value: '1',
            },
          ],
          render: ({ text }) => {
            return text == '0' ? (
              <span style={{ color: '#5fce60' }}>成功</span>
            ) : (
              <span style={{ color: '#de350c' }}>失败</span>
            )
          },
        },
        {
          title: '日志',
          dataIndex: 'log',
          align: 'left',
          render: ({ records }) => {
            const data = [
              {
                name: '查看',
                display: true,
                onClick: (records) => {
                  this.visible = true
                  // this.handleAddData(records)
                },
              },
            ]
            return <TableButton data={data} />
          },
        },
        {
          title: '操作',
          dataIndex: 'a1',
          render: ({ records }) => {
            const data = [
              {
                name: '编辑',
                display: true,
                onClick: (records) => {
                  this.$router.psu(`/exchange/detail?id=${records.id}`)
                },
              },
              {
                name: '详情',
                display: true,
                onClick: (records) => {
                  this.$router.psu(`/exchange/detail?id=${records.id}`)
                },
              },
            ]
            return <TableButton data={data} />
          },
        },
      ]
    },

    handleAddData(records) {
      return (
        <a-drawer
          width="600"
          title="交换日志"
          placement="right"
          closable="false"
          visible={this.visible}
          after-visible-change="afterVisibleChange"
          onClose={() => {
            this.visible = false
          }}
        >
          <Table />
        </a-drawer>
      )
    },
  },
  render() {
    return (
      <div>
        <TemplateHeaderTable
          // url={'/api/dataoperation/collectionMainBody/page'}
          data={this.dataList}
          tableColumns={this.getColumns()}
          headerData={this.getHeader()}
          button={this.getButton()}
          ref={'table'}
        />
        {this.handleAddData()}
      </div>
    )
  },
}
</script>
